import React from "react";
import { Flipper, Flipped } from "react-flip-toolkit";
import { Button, ButtonGroup } from "reactstrap";
//import { Link } from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import Card from "../../components/card";

//import tempThumb from "../images/tempThumb.png";

import niflibThumb from "../../images/niflib-thumb.png";
import nifMayaThumb from "../../images/nif-maya-thumb.png";
import mgeThumb from "../../images/mge-thumb.png";
import aoThumb from "../../images/ao-thumb.png";
import vrThumb from "../../images/vr-thumb.png";
import emuThumb from "../../images/emu-thumb.png";
import gamebryoThumb from "../../images/gamebryo-thumb.png";
import pmThumb from "../../images/pm-thumb.png";
import kakimashouThumb from "../../images/kakimashou-thumb.png";

const data = [
  {
    title: "ゲームボーイエミュレータ",
    people: 1,
    image: emuThumb,
    link: "emulator",
    tags: ["C++", "OpenGL"]
  },
  {
    title: "アンビエント・オクルージョンのデモ",
    people: 1,
    image: aoThumb,
    link: "ambient-occlusion",
    tags: ["グラフィックス", "TypeScript", "GLSL", "WebGL"]
  },
  {
    title: "Room Escape VRゲーム",
    people: 4,
    image: vrThumb,
    link: "room-escape",
    tags: ["ゲームプレイ", "C#", "Unity", "Maya"]
  },
  {
    title: "モロウウィンド・グラフィックス・エクステンダー (MGE)",
    people: 2,
    image: mgeThumb,
    link: "mge",
    tags: ["ツール", "グラフィックス", "DirectX", "HLSL"]
  },
  // {
  //   title: "Mayaのインポーターとエクスポーター",
  //   people: 5,
  //   image: nifMayaThumb,
  //   link: "nif-maya",
  //   tags: ["ツール", "C++", "MEL", "Maya"]
  // },
  // {
  //   title: "Niflib",
  //   people: 5,
  //   image: niflibThumb,
  //   link: "niflib",
  //   tags: ["ツール", "C++", "Python"]
  // },
  {
    title: "NifTools",
    people: 5,
    image: nifMayaThumb,
    link: "niftools",
    tags: ["ツール", "C++", "MEL", "Maya", "Python"]
  },
  {
    title: "Atellica Process Manager",
    people: 15,
    image: pmThumb,
    link: "process-manager",
    tags: ["ツール", "C#", "WPF", "3ds Max", "Blender"]
  },
  {
    title: "Gamebryoゲームエンジン",
    people: 15,
    image: gamebryoThumb,
    link: "gamebryo",
    tags: ["グラフィックス", "ツール", "C++", "Lua", "Maya"]
  },
  {
    title: "Kakimashou.com",
    people: 15,
    image: kakimashouThumb,
    link: "kakimashou",
    tags: ["C#", "PHP", "JavaScript", "SQLite"]
  },
  // {
  //   id: 7,
  //   title: "Visual Novel Demo",
  //   people: 1,
  //   image: myImage,
  //   link: "visual-novel",
  //   tags: ["ゲームプレイ", "Lua", "C++"]
  // },
  // {
  //   id: 8,
  //   title: "RPG Demo",
  //   people: 1,
  //   image: myImage,
  //   link: "rpg",
  //   tags: ["ゲームプレイ", "C++"]
  // },
  // {
  //   id: 9,
  //   title: "TBD: Unreal Demo",
  //   people: 1,
  //   image: myImage,
  //   tags: ["ゲームプレイ", "Unreal"]
  // },
  // {
  //   id: 10,
  //   title: "TBD: Graphics Demo",
  //   people: 1,
  //   image: myImage,
  //   tags: ["グラフィックス", "C++", "DirectX", "OpenGL", "Vulkan"]
  // }
];

class IndexPage extends React.Component {
  state = {
    type: "grid",
    sort: "asc",
    filteredIds: [],
    stagger: "forward",
    spring: "noWobble"
  };

  addToFilteredIds = id => {
    this.setState(prevState => {
      return {
        filteredIds: prevState.filteredIds.concat(id)
      };
    });
  };

  filterList(tag) {
    var newList = [];
    if (tag !== "All") {
      [...data]
        .filter(d => !d.tags.includes(tag))
        .map(({ id }) => newList.push(id));
    }

    this.setState({ filteredIds: newList });
  }

  render() {
    let count = 0;
    for (var item of data) {
      item["id"]= count++;
    }

    return (
      <div>
        <Layout location={this.props.location}>
          <SEO title="ポートフォリオ" />
          <ButtonGroup>
            <Button onClick={() => this.filterList("All")}>全部</Button>
            <Button onClick={() => this.filterList("グラフィックス")}>
            グラフィックス
            </Button>
            <Button onClick={() => this.filterList("ツール")}>ツール</Button>
            <Button onClick={() => this.filterList("ゲームプレイ")}>
            ゲームプレイ
            </Button>
          </ButtonGroup>
        </Layout>

        <div className="fm-example">
          <Flipper
            flipKey={`${this.state.type}-${this.state.sort}-${JSON.stringify(
              this.state.filteredIds
            )}-${JSON.stringify(this.state.stagger)}`}
            spring={this.state.spring}
            staggerConfig={{
              default: {
                reverse: this.state.stagger !== "forward",
                speed: 1
              }
            }}
            decisionData={this.state}
          >
            <Flipped flipId="list">
              <div
                className={this.state.type === "grid" ? "fm-grid" : "fm-list"}
              >
                <Flipped inverseFlipId="list">
                  <ul className="list-contents">
                    {[...data]
                      .filter(d => !this.state.filteredIds.includes(d.id))
                      .sort((a, b) => {
                        if (this.state.sort === "asc") {
                          return a.id - b.id;
                        } else {
                          return b.id - a.id;
                        }
                      })
                      .map(({ title, id, people, tags, image, link }) => (
                        <Card
                          id={id}
                          title={title}
                          people={people}
                          tags={tags}
                          image={image}
                          link={link}
                          lang="ja"
                          stagger={["forward", "reverse"].includes(
                            this.state.stagger
                          )}
                          type={this.state.type}
                          key={id}
                          addToFilteredIds={this.addToFilteredIds}
                        />
                      ))}
                  </ul>
                </Flipped>
              </div>
            </Flipped>
          </Flipper>
        </div>
      </div>
    );
  }
}

export default IndexPage;
